























import Vue from 'vue'

import { Address } from '@/types'

export default Vue.extend({
	name: 'CheckoutAddressCard',
	props: {
		title: {
			default: undefined,
			type: String as Vue.PropType<string>,
		},
		address: {
			required: true,
			type: Object as Vue.PropType<Address>,
		},
	},
})
