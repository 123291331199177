































































































































































































import Vue from 'vue'

import { Pagination } from 'vuelpers'
import { mapActions, mapGetters } from 'vuex'
import { Accessors } from 'vue/types/options'
import { getValidators } from '@/mixins/FormMixin'
import { Address, Country, County, Currency, User, VFormRef } from '@/types'
import { isPlainObject, isString } from 'lodash'
import { isValid } from 'postcode'

const newAddress = (v?: Address) => {
	return {
		iAddressId: v?.iAddressId,
		iCountyId: v?.county,
		iCountryId: v?.country?.iCountryId,
		eDefault: v?.eDefault,
		addressableId: v?.addressableId,
		addressableType: v?.addressableType,
		vAddress: v?.vAddress,
		vAddress2: v?.vAddress2,
		vCity: v?.vCity,
		vZipCode: v?.vZipCode,
		vPhone: v?.vPhone,
		vMobile: v?.vMobile,
		vEmail: v?.vEmail,
		vContactName: v?.vContactName,
		vCompanyName: v?.vCompanyName,
	} as Partial<Address>
}

type FLName = {
	firstName: string
	lastName: string
}

export default Vue.extend({
	name: 'FormAddress',
	props: {
		hideCancel: {
			default: false,
			type: Boolean as Vue.PropType<boolean>,
		},
		address: {
			default: undefined,
			type: Object as Vue.PropType<Address>,
		},
	},
	data: () => ({
		isLoading: false,
		isFormValid: false,
		mAddress: newAddress(),
		rules: getValidators('email', 'phone', 'required'),
		vContactName: {
			firstName: '',
			lastName: '',
		},
	}),
	created() {
		this.onInitialize()
	},
	watch: {
		address: {
			deep: true,
			immediate: true,
			handler(address: Address | undefined) {
				if (!address) return
				this.mAddress = newAddress(address)
				this.vContactName = this.fullNameToSplitName(
					address.vContactName || ''
				)
			},
		},
	},
	computed: {
		...({
			...mapGetters(['$currentCurrency']),
			...mapGetters('county', ['$counties']),
			...mapGetters('country', ['$countries']),
			...mapGetters('auth', ['$currentUser', '$isLoggedIn']),
		} as Accessors<{
			$currentUser: User
			$isLoggedIn: boolean
			$counties: Pagination<County>
			$countries: Pagination<Country>
			$currentCurrency?: Currency
		}>),
		uk(): Country | undefined {
			return this.$countries.data.find((country) => {
				return country.vCountryCode === 'UK'
			})
		},
		isUK(): boolean {
			return this.mAddress.iCountryId === this.uk?.iCountryId
		},
		zipPostCode(): string {
			return this.$currentCurrency?.code === 'GBP' ? 'Post Code' : 'Zip Code'
		},
	},
	methods: {
		...mapActions('county', ['getCounties']),
		...mapActions('country', ['getCountries']),
		...mapActions('carts', ['syncAddress']),
		rulePostCode(v: any) {
			if (!v || !this.isUK) return true
			return isValid(v.toString()) || 'Invalid Zip Code'
		},
		fullNameToSplitName(fullName: string): FLName {
			const [firstName, ...restName] = fullName.split(' ')
			return { firstName, lastName: restName.join(' ') }
		},
		splitToFullName(name: FLName): string {
			return `${name.firstName} ${name.lastName}`
		},
		onInitialize() {
			this.getCounties()
			this.getCountries({ perPage: 500 })
		},
		formRef() {
			return this.$refs.formRef as VFormRef
		},
		onCancel(event: any) {
			this.formRef().reset()
			this.$emit('cancel', event)
		},
		async onSubmitAddress() {
			const formRef = this.formRef()
			if (!formRef.validate()) return

			const adrs = this.mAddress as any
			const address = {
				...adrs,
				eDefault: adrs.eDefault || 'Yes',
				addressableType: adrs.addressableType || 'App\\Models\\Customer',
				addressableId:
					adrs.addressableId || this.$currentUser?.customer?.iCustomerId,
				vContactName: this.splitToFullName(this.vContactName),
				iCountyId: isString(adrs.iCountyId)
					? adrs.iCountyId
					: adrs.iCountyId?.iCountyId,
				iCountryId: isPlainObject(adrs.iCountryId)
					? adrs.iCountryId?.iCountryId
					: adrs.iCountryId,
			}

			this.isLoading = true
			let [error, syncAddress] = await this.syncAddress(address)
			this.isLoading = false

			if (!error) {
				this.$emit('address', syncAddress)
				// formRef.reset()
			}
		},
	},
})
