






















































import Vue from 'vue'

import { mapGetters } from 'vuex'
import { Address, Id, User } from '@/types'
import { Accessors } from 'vue/types/options'

import AddressCard from '@/components/CheckoutAddressCard.vue'
import FormAddress from '@/components/Forms/FormAddress.vue'
import { APP_META } from '@/consts'
import { MetaInfo } from 'vue-meta'

export default Vue.extend({
	name: 'Addresses',
	metaInfo(): MetaInfo {
		return {
			...APP_META,
			title: `Addresses | ${APP_META.title}`,
		}
	},
	components: {
		AddressCard,
		FormAddress,
	},
	data: () => ({
		dialogAddress: false,
	}),
	computed: {
		...({
			...mapGetters('auth', ['$currentUser']),
		} as Accessors<{
			$currentUser: User
		}>),
		addresses(): Address[] {
			return this.$currentUser.customer?.addresses ?? []
		},
	},
	methods: {
		onClickDeleteAddress(iAddressId: Id) {
			this.$store.dispatch('auth/deleteAddress', iAddressId)
		},
		onAddress() {
			this.dialogAddress = false
		},
	},
})
